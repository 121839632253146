import React, { useState } from "react";
import "./dans.css";
import useTranslate from "../../hooks/useTranslate";
import { positions, Provider, useAlert } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER,
};
const OrgolGolomt = () => {
  const [isBankNameCopied, setIsBankNameCopied] = useState(false);
  const [isBankNumberCopied, setIsBankNumberCopied] = useState(false);
  const [iskhanBankNameCopied, setIskhanBankNameCopied] = useState(false);
  const [iskhanBankNumberCopied, setIskhanBankNumberCopied] = useState(false);
  


  const bankName = "Buman khan kholboo";
  const bankNumber = "1415109047";
  const kbankName = "Chimedzundui Gunzee";
  const kbankNumber = "5169438333";
  

  const { language, setLanguage, t } = useTranslate("mn");

  const copyBankName = () => {
    navigator.clipboard
      .writeText(bankName)
      .then(() => setIsBankNameCopied(true))
      .catch(() => setIsBankNameCopied(false));
  };

  const copyBankNumber = () => {
    navigator.clipboard
      .writeText(bankNumber)
      .then(() => setIsBankNumberCopied(true))
      .catch(() => setIsBankNumberCopied(false));
  };
  const copykBankName = () => {
    navigator.clipboard
      .writeText(kbankName)
      .then(() => setIskhanBankNameCopied(true))
      .catch(() => setIskhanBankNameCopied(false));
  };

  const copykBankNumber = () => {
    navigator.clipboard
      .writeText(kbankNumber)
      .then(() => setIskhanBankNumberCopied(true))
      .catch(() => setIskhanBankNumberCopied(false));
  };


  return (
    <Provider template={AlertTemplate} {...options}>
      <div className="donate-page">
        <div className="image-container">
          <img src="/donation-background.webp" />
        </div>
        <div className="donate-form-container">
          <div>
            <center>
              {/* <h4>"Буман ханд"</h4> */}
              <div>
                <img src="BumanHand-removebg.png" style={{ width: 96 }} />
              </div>
              <h6>{t("home_subtitle")}</h6>
            </center>

            <Tab
              tabs={[t("Khan"), t("Golomt")]}
              contents={[
                [
                  <center>
                    <img
                      src="Khanbank.jpg"
                      style={{
                        width: "35%",
                        borderRadius: 8,
                        marginBottom: 18,
                      }}
                    />
                  </center>,
                  <div className="account-item">
                    <div>
                      <div className="label">
                        {t("donation_page_tab_item_dans")}
                      </div>
                      <div className="text">{t("terguun_name")}</div>
                    </div>
                    <div>
                      <CopyButton text={kbankName} />
                    </div>
                  </div>,
                  <div className="account-item">
                    <div>
                      <div className="label">
                        {t("donation_page_tab_item_account")}
                      </div>
                      <div className="text">5169438333</div>
                    </div>
                    <div>
                      <CopyButton text={kbankNumber} />
                    </div>
                  </div>,
                <div className="swif-div">
                  <div >
                    <div className="swif">
                      {t("swif_div")}
                    </div>
                    <div className="p-text">{t("p_text")} <br />
                    {t("p_text1")} <br />
                     SWIF/BIC: AGMOMNUB</div>
                    
                  </div>
                </div>,
                <div className="suggest-div">
                  <div className="sug-title">
                  {t("sug_title")}
                  </div>
                  <div className="sug-text">
                  {t("sug_text")}<br />
                  {t("sug_text1")}
                  </div>
                  

                </div>
                  // terguun_name
                ],
                [
                  <center>
                    <img
                      src="golomt.jpg"
                      style={{
                        width: "35%",
                        borderRadius: 8,
                        marginBottom: 18,
                      }}
                    />
                  </center>,
                  <div className="account-item">
                    <div>
                      <div className="label">
                        {t("donation_page_tab_item_dans")}
                      </div>
                      <div className="text">{t("buman_d")}</div>
                    </div>
                    <div>
                      <CopyButton text={bankName} />
                    </div>
                  </div>,
                  <div className="account-item">
                    <div>
                      <div className="label">
                        {t("donation_page_tab_item_account")}
                      </div>
                      <div className="text">1415109047</div>
                    </div>
                    <div>
                      <CopyButton text={bankNumber} />
                    </div>
                  </div>,
                  <div className="suggest-div">
                  <div className="sug-title">
                  {t("sug_title")}
                  </div>
                  <div className="sug-text">
                  {t("sug_text")}<br />
                  {t("sug_text1")}
                  </div>
                  

                </div>
                  // terguun_name
                ],
              ]}
            />
          </div>
          <center className="py-2">
            <a href="/" style={{ fontSize: 20, fontWeight: "bold" }}>
              {t("back_to_home")}
            </a>
          </center>
        </div>
      </div>
    </Provider>
  );
};

function CopyButton({ text }) {
  const alert = useAlert();
  const { t } = useTranslate("mn");
  const onClick = () => {
    navigator.clipboard.writeText(text);
    alert.show(t("copy_result"));
    // .then(() => setIskhanBankNumberCopied(true))
    // .catch(() => setIskhanBankNumberCopied(false));
  };

  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      {/* <i class="fa-sharp fa-regular fa-clipboard"></i> */}
      <span>{t("copy_text")}</span>
    </div>
  );
}

function Tab({ contents = [], tabs = [] }) {
  const [selected, setSelected] = React.useState(0);

  return (
    <div className="donate-tab">
      <div className="tab-headers">
        {tabs.map((e, index) => {
          return (
            <div
              onClick={() => setSelected(index)}
              className={`tab-header-items ${
                index === selected && "tab-header-items-active"
              }`}
            >
              {e}
            </div>
          );
        })}
      </div>
      <div className="tab-contents py-4">
        {contents[selected]}
        {/* {contents.map((e, index) => (
          <div
            key={index}
            className={`tab-content ${index === selected && "active"}`}
          >
            {e}
          </div>
        ))} */}
      </div>
      <div></div>
    </div>
  );
}

export default OrgolGolomt;
