import { useContext } from "react";
import { TranslationContext } from "./TranslationProvider";

const useTranslate = () => {
  const context = useContext(TranslationContext);

  if (!context) {
    throw new Error("useTranslation must be used within a TranslationProvider");
  }

  return {
    t: context.t,
    language: context.language,
    setLanguage: context.setLanguage,
  };
};

export default useTranslate;
