import useTranslate from "../../hooks/useTranslate";
import React from "react";

const SurgaltPage = () => {
  const { language, setLanguage, t } = useTranslate("mn");
  return (
    <div>
      <div
        class="sigma_subheader dark-overlay dark-overlay-2"
        style={{
          backgroundImage:
            "url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
          height: "60vh",
          paddingTop: "25vh",
        }}
        // style="background-image: "
      >
        <div class="container">
          <div class="sigma_subheader-inner">
            <div class="sigma_subheader-text">
              <h1>{t("surgalt")}</h1>
            </div>
            
          </div>
        </div>
      </div>

      <div class="section section-padding">
        <div class="container">
          <div class="row">
            <SurgaltItem
              imgSrc={"/tsagaan_dari_eh.jpg"}
              title={t("dari_eh")}
              id="burhan_hotolbor"
            >
              <p>{t("dari_eh_text")}</p>
              <p>{t("dari_eh_text1")}</p>
              <p>{t("dari_eh_text2")}</p>
            </SurgaltItem>

            <SurgaltItem
              imgSrc={"/jod_lujin.jpg"}
              title={t("jod")}
              id="jod_luujin"
            >
              <p>{t("jod_luijin_text")}</p>
              <p>{t("jod_luijin_text1")}</p>
              <p>{t("jod_luijin_text2")}</p>
              <p>{t("jod_luijin_text3")}</p>
              <p>{t("jod_luijin_text4")}</p>
              <p>{t("jod_luijin_text5")}</p>
              <p>{t("jod_luijin_text6")}</p>
              <p>{t("jod_luijin_text7")}</p>
              <p>{t("jod_luijin_text8")}</p>
              <p>{t("jod_luijin_text9")}</p>
            </SurgaltItem>
            <SurgaltItem
              imgSrc={"/sudar_altan.jpeg"}
              title={t("altan")}
              id="altan-surah"
            >
              {t("altangerel_text")}
            </SurgaltItem>
            <SurgaltItem
              imgSrc={
                "/tsagaan.jpg"
              }
              title={t("dari_eh10")}
              id="Dari_eh"
            >
              <p>{t("dari_eh10_text")}</p>
              <p>{t("dari_eh10_text1")}</p>
              <p>{t("dari_eh10_text2")}</p>
              <p>{t("dari_eh10_text3")}</p>
              <p>{t("dari_eh10_text4")}</p>
              <p>{t("dari_eh10_text5")}</p>
              <p>{t("dari_eh10_text6")}</p>
              <p>{t("dari_eh10_text7")}</p>
              <p>{t("dari_eh10_text8")}</p>
            </SurgaltItem>

            <SurgaltItem
              imgSrc={
                    "/towd.jpeg"
              }
              title={t("towd")}
              id="towd_hel"
            >
              <p>{t("towhel_hel")}</p>
              <p>{t("towhel_hel1")}</p>
              <p>{t("towhel_hel2")}</p>
              <p>{t("towhel_hel3")}</p>
              <p>{t("towhel_hel4")}</p>
              <p>{t("towhel_hel5")}</p>
              <p>{t("towhel_hel6")}</p>
              <p>{t("towhel_hel7")}</p>
            </SurgaltItem>

            <SurgaltItem
              imgSrc={
                "/21dari_eh.jpeg"
              }
              title={t("dari_eh21")}
              id="dari_eh"
            >
             <p>{t("dari_eh21_text")}</p> 
             <p>{t("dari_eh21_text1")}</p> 
             <p>{t("dari_eh21_text2")}</p> 
             <p>{t("dari_eh21_text3")}</p> 
             <p>{t("dari_eh21_text4")}</p> 
             <p>{t("dari_eh21_text5")}</p> 

            </SurgaltItem>

            <SurgaltItem
              imgSrc={
                "/altangerel_sudar.jpg"
              }
              title="АЛТАНГЭРЭЛ СУДАР УНШИЖ СУРАХ "
              id="altan_gerel"
            >
              Алтангэрэл сударыг үсэг тоолон уншиж сурах хичээл. Төвөд бичиг
              сурч буй болон үсэглэн уншаг, хөлгөн судар уншиж сурахыг зорьж буй
              хүмүүст зориулсан хичээл юм. Тус хичээлээр судрын аймгийн хаан
              гэгдэх Алтангэрэл судрыг төвөд хэлээр хөтлөн уншиж тайлбарлана.
              Хөлгөн судрын үг хэллэг, тарнийг зөв дуудах дасгал хийснээр бусад
              судар ном уншихад дэмтэй болно. Судрыг файл буюу дижитал хэлбэрээр
              өгнө. Алтангэрэл судрын Хорин нэгдүгээр бүлэг хүртэл 56 видео
              хичээл дагалдана. юм.
            </SurgaltItem>
          </div>
        </div>
      </div>
      <div className="section pt-0 mt-50">
    <div className="container">

      <form className="sigma_box box-lg m-0 mf_form_validate ajax_submit" action="sendmail.php" method="post" enctype="multipart/form-data" novalidate="novalidate">
        <div className="row">
          
          <div className="holboo col-lg-4">
            <div className="form-group">
              <i className="far fa-user"></i>
              <input type="text" placeholder={t("owog")} className="form-control dark" name="name" />
            </div>
          </div>
          <div className="holboo col-lg-4">
            <div className="form-group">
              <i className="far fa-envelope"></i>
              <input type="email" placeholder={t("utas")} className="form-control dark" name="email" />
            </div>
          </div>
          <div className="holboo col-lg-4">
            <div className="form-group">
              <i className="far fa-pencil"></i>
              <input type="text" placeholder={t("con_sur")} className="form-control dark" name="Subesubject" />
            </div>
          </div>
          
        </div>
        <div className="form-group">
          <textarea name="message" placeholder="Тeкст" cols="45" rows="5" className="form-control dark"></textarea>
        </div>
        <div className="text-center">
          <button type="submit" className="sigma_btn-custom" name="button">{t("ilgeeh")}</button>
          <div className="server_response w-100">
          </div>
        </div>
      </form>

    </div>
  </div>
    </div>
  );
};
export default SurgaltPage;

function SurgaltItem({ imgSrc, title, children, id }) {
  const [isShow, setIsShow] = React.useState(false);

  return (
    <div class="col-lg-4 col-md-6">
      <a class="sigma_service style-2">
        <div class="sigma_service-thumb">
          <img src={imgSrc} style={{height:"200px"}} alt="img" />
          <i class="flaticon-yin-yang"></i>
        </div>
        <div class="sigma_service-body">
          <div className="accordion with-gap" id={`${id}_parent`}>
            <div className="card">
              <div
                className="card-header"
                aria-expanded={isShow?true:false}
                onClick={() => setIsShow(!isShow)}
              >
                {title}
              </div>
              <div
                id={id + ""}
                className={`collapse ${isShow ? "show" : ""}`}
                data-bs-parent={`${id}_parent`}
              >
                <div className="card-body">
                  <p
                    style={
                      {
                        //   color: "gray",
                      }
                    }
                  >
                    {children}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}


