import useTranslate from "../../hooks/useTranslate";
import "./zurag.css";
import React from "react";

const ZuragPage = () => {
  const { t } = useTranslate("mn");


  const [selectImage, setSelectImage] = React.useState();


  const onClickImage = (id) => {
    setSelectImage(id);
  };

  return (
    <div className="main">
      <div
        class="sigma_subheader dark-overlay dark-overlay-2"
        style={{
          backgroundImage:
            "url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
          height: "60vh",
          paddingTop: "25vh",
          width: "100vw",
        }}
        //style="background-image: "
      >
        <div class="container">
          <div class="sigma_subheader-inner">
            <div class="sigma_subheader-text">
              <h1>{t("zurag_link")} </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="main-div">
        {/* <div className=" zuragnuud">
          <img
            onClick={handleClick}
            src="assets/img/buddha_images/image9.jpg"
            alt=""
          />
          <img
            onClick={FirstClick}
            src="assets/img/buddha_images/IMG_3435.jpg"
            alt=""
          />
          <img
            onClick={handleClick}
            src="assets/img/buddha_images/IMG_6899.JPG"
            alt=""
          />
          <img src="assets/img/buddha_images/IMG-3157.JPG" alt="" />
          <img src="assets/img/buddha_images/IMG-3166.JPG" alt="" />
          <img src="assets/img/buddha_images/IMG-3171.JPG" alt="" />
          <img src="assets/img/buddha_images/IMG-6965.JPG" alt="" />
          <img src="assets/img/buddha_images/emegtei.jpg" alt="" />
          <img src="assets/img/buddha_images/zurag1.jpg" alt="" />
          <img src="assets/img/buddha_images/zurag2.jpg" alt="" />
        </div>
        <div className=" zuragnuud ">
          <img src="assets/img/buddha_images/dara eh.jpg" alt="" />
          <img src="assets/img/buddha_images/IMG_7029.JPG" alt="" />
          <img src="assets/img/buddha_images/IMG_8343.JPG" alt="" />
          <img src="assets/img/buddha_images/IMG_8346.JPG" alt="" />
          <img src="assets/img/buddha_images/IMG_8727.JPG" alt="" />
          <img src="assets/img/buddha_images/IMG-3174.JPG" alt="" />
          <img src="assets/img/buddha_images/IMG-3296.jpg" alt="" />
          <img src="assets/img/buddha_images/IMG-4268.jpg" alt="" />
          <img src="assets/img/buddha_images/lamtai hamt.jpg" alt="" />
          <img src="assets/img/buddha_images/zurag3.jpg" alt="" />
        </div>
        <div className=" zuragnuud ">
          <img src="assets/img/buddha_images/IMG_6257.JPG" alt="" />
          <img src="assets/img/buddha_images/IMG_6278.JPG" alt="" />
          <img src="assets/img/buddha_images/IMG_6885.JPG" alt="" />
          <img src="assets/img/buddha_images/IMG-0415.JPG" alt="" />
          <img src="assets/img/buddha_images/IMG-2945.jpg" alt="" />
          <img src="assets/img/buddha_images/IMG-7725.jpg" alt="" />
          <img src="assets/img/buddha_images/IMG-7912.JPG" alt="" />
          <img src="assets/img/buddha_images/IMG-9671.JPG" alt="" />
          <img src="assets/img/buddha_images/emgtai.jpeg" alt="" />
          <img src="assets/img/buddha_images/IMG-6312.jpg" alt="" />
          <img src="assets/img/buddha_images/zurag.jpg" alt="" />
        </div> */}

        <div className=" zuragnuud ">
          {images[0].map((e, index) => {
            return <img src={e} alt="" onClick={() => onClickImage(e)} />;
          })}
        </div>

        <div className=" zuragnuud ">
          {images[1].map((e, index) => {
            return <img src={e} alt="" onClick={() => onClickImage(e)} />;
          })}
        </div>

        <div className=" zuragnuud ">
          {images[2].map((e, index) => {
            return <img src={e} alt="" onClick={() => onClickImage(e)} />;
          })}
        </div>

        {selectImage && (
          <div
            className="imgs position-fixed"
            onClick={() => onClickImage(null)}
            style={{ visibility: "visible" ,
            
          }}
          >
            <img style={{
              height: "80vh",
              width: "auto",
            }} src={selectImage} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};
export default ZuragPage;

const images = [
  [
    "news5.jpg",
    "news8.jpg",
    "news.jpg",
    "assets/img/buddha_images/image9.jpg",
    "assets/img/buddha_images/IMG_3435.jpg",
    "assets/img/buddha_images/IMG_6899.JPG",
    "assets/img/buddha_images/IMG-3157.JPG",
    "assets/img/buddha_images/IMG-3166.JPG",
    "assets/img/buddha_images/IMG-3171.JPG",
    "assets/img/buddha_images/IMG-6965.JPG",
    "assets/img/buddha_images/emegtei.jpg",
    "assets/img/buddha_images/zurag1.jpg",
    "assets/img/buddha_images/zurag2.jpg",
  ],
  [
    "news_buddha1.jpg",
    "news7.jpg",
    "news_photo.jpg",
    "news1.jpg",
    "assets/img/buddha_images/dara eh.jpg",
    "assets/img/buddha_images/IMG_7029.JPG",
    "assets/img/buddha_images/IMG_8343.JPG",
    "assets/img/buddha_images/IMG_8346.JPG",
    "assets/img/buddha_images/IMG_8727.JPG",
    "assets/img/buddha_images/IMG-3174.JPG",
    "assets/img/buddha_images/IMG-3296.jpg",
    "assets/img/buddha_images/IMG-4268.jpg",
    "assets/img/buddha_images/lamtai hamt.jpg",
    "assets/img/buddha_images/zurag3.jpg",
  ],
  [
    "news4.jpg",
    "news2.jpg",
    "news3.jpg",
    "assets/img/buddha_images/IMG_6257.JPG",
    "assets/img/buddha_images/IMG_6278.JPG",
    "assets/img/buddha_images/IMG_6885.JPG",
    "assets/img/buddha_images/IMG-0415.JPG",
    "assets/img/buddha_images/IMG-2945.jpg",
    "assets/img/buddha_images/IMG-7725.jpg",
    "assets/img/buddha_images/IMG-7912.JPG",
    "assets/img/buddha_images/IMG-9671.JPG",
    "assets/img/buddha_images/emgtai.jpeg",
    "assets/img/buddha_images/IMG-6312.jpg",
    "assets/img/buddha_images/zurag.jpg",
  ],
];
