
export default function AboutVideo() {


    return (
        <div className="section section-lg bg-cover bg-norepeat bg-center" style={{ backgroundImage: 'url(assets/img/bg1.jpg)' }}>
            <div className="section d-flex align-items-center justify-content-center">
                <a target="blank" href="https://www.youtube.com/@kunze2264/videos" className="sigma_video-popup popup-youtube">
                    <i className="fas fa-play" />
                </a>
            </div>
        </div>

    )
}