import React from "react";
import Slider from "react-slick";
import useTranslate from "../hooks/useTranslate";

export default function PorfolioSection() {
    const settings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: false,
        // prevArrow: window.$('.portfolio-section .slider-prev'),
        // nextArrow: window.$('.portfolio-section .slider-next'),
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    }
    // React.useEffect(() => {
    //     if(window.__porfolio_slider) {
    //         window.__porfolio_slider();
    //     }
    // },[])

    return (
        <div className="section section-padding bg-cover portfolio-section" style={{ backgroundImage: 'url(/assets/img/textures/abstract.png)' }}>
            <div className="container">
                <div className="section-title section-title-2 flex-title">
                    <div>
                        <p className="subtitle light">Portfolio</p>
                        <h4 className="text-white title mb-lg-0">Our Community</h4>
                    </div>
                    <div className="sigma_arrows">
                        <i className="far fa-chevron-left slick-arrow slider-prev" />
                        <i className="far fa-chevron-right slick-arrow slider-next" />
                    </div>
                </div>
                <Slider {...settings} autoplay className="portfolio-slider">
                    <div className="sigma_portfolio-item style-3">
                        <img src="news.jpg" alt="portfolio" />
                        <div className="sigma_portfolio-item-content">
                            <div className="sigma_portfolio-item-content-inner">
                                <a href="/zurag">Temple</a>
                                <h5> <a href="/zurag">Community </a> </h5>
                            </div>
                            <a href="/zurag"><i className="far fa-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="sigma_portfolio-item style-3">
                        <img src="news2.jpg" alt="portfolio" />
                        <div className="sigma_portfolio-item-content">
                            <div className="sigma_portfolio-item-content-inner">
                                <a href="/zurag">Temple</a>
                                <h5> <a href="/zurag"> Meditation </a> </h5>
                            </div>
                            <a href="/zurag"><i className="far fa-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="sigma_portfolio-item style-3">
                        <img src="news7.jpg" alt="portfolio" />
                        <div className="sigma_portfolio-item-content">
                            <div className="sigma_portfolio-item-content-inner">
                                <a href="/zurag">Temple</a>
                                <h5> <a href="/zurag"> Buddhisms </a> </h5>
                            </div>
                            <a href="/zurag"><i className="far fa-arrow-right" /></a>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>

    )
}

export function PorfolioNoslider() {
    const { t } = useTranslate("mn");
    return (
        <div className="section section-padding">
            <div className="container-fluid">
                <div className="row">
                    <div  className="col-lg-4 coaching">
                        <a href="/zurag">
                        <div className="sigma_portfolio-item">
                            <img src="news.jpg" alt="portfolio" />
                            <div className="sigma_portfolio-item-content">
                                <div className="sigma_portfolio-item-content-inner">
                                    <h5> <a href="/zurag">  {t("zurag_link")} </a> </h5>
                                    
                                </div>
                                <a href="/zurag"><i className="fal fa-plus" /></a>
                            </div>
                        </div>
                        </a>
                    </div>
                    <div className="col-lg-4 strategy">
                        <a href="/zurag">
                        <div className="sigma_portfolio-item">
                            <img src="news2.jpg" alt="portfolio" />
                            <div className="sigma_portfolio-item-content">
                                <div className="sigma_portfolio-item-content-inner">
                                    <h5> <a href="/zurag">  {t("zurag_link")} </a> </h5>
                                    
                                </div>
                                <a href="/zurag"><i className="fal fa-plus" /></a>
                            </div>
                        </div></a>
                    </div>
                    <div className="col-lg-4 coaching strategy">
                        <a href="/zurag">
                        <div className="sigma_portfolio-item">
                            <img src="news7.jpg" alt="portfolio" />
                            <div className="sigma_portfolio-item-content">
                                <div className="sigma_portfolio-item-content-inner">
                                    <h5> <a href="/zurag">  {t("zurag_link")} </a> </h5>
                                    
                                </div>
                                <a href="/zurag"><i className="fal fa-plus" /></a>
                            </div>
                        </div></a>
                    </div>
                </div>
            </div>
        </div>

    )
}