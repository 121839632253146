import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../hooks/useTranslate";
import { Link } from "react-router-dom";

export default function Footer() {
  const { language, setLanguage, t } = useTranslate("mn");
  return (
    <footer
      className="sigma_footer footer-2 bg-cover bg-center bg-transparent light-overlay"
      style={{ backgroundImage: "url(/assets/img/footer.jpg)" }}
    >
      <div className="sigma_footer-middle">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 footer-widget">
              <div className="sigma_footer-logo mb-4">
                <img
                  src="BumanHand.jpg"
                  alt="logo"
                  style={{
                    width: "100px",
                  }}
                />
              </div>
              <p className="mb-4">{t("home_subtitle")}</p>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 footer-widget">
              <h5 className="widget-title">{t("holboo")}</h5>
              <div className="d-flex gap-2 align-items-center">
                <FontAwesomeIcon icon={faPhone} />
                {t("utas")}:
              </div>
              <div className="d-flex  justify-content-md-start align-items-center">
                <span>(+976) 99087125</span>
              </div>
              <div className="d-flex  justify-content-md-start align-items-center">
                <span>(+976) 70183588</span>
              </div>
              <div className="d-flex gap-2 align-items-center mt-2">
                <FontAwesomeIcon icon={faEnvelope} />
                {t("e_mail")}:
              </div>
              <div className="d-flex  justify-content-md-start">
                <span>INFO@BUDDHISTWOMEN.MN</span>
              </div>
              <div className="d-flex gap-2 align-items-center mt-2">
                <FontAwesomeIcon icon={faMapLocationDot} />
                {t("hayg")}:
              </div>
              <div className="d-flex  justify-content-md-start">
                <span>{t("hayg_text")}</span>
              </div>
            </div>
            <div className="undes col-xl-3 col-lg-3 col-md-3 col-sm-12 footer-widget">
              <h5 className="widget-title">{t("undes")}</h5>
              <ul>
                <li>
                  {" "}
                  <a href="/about">{t("bidnii")}</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/medeeD">{t("Medee")}</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/bichleg">{t("unshlag")}</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/surgalt">{t("bichleg")}</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/handiw">{t("orgol")}</a>{" "}
                </li>
              </ul>
            </div>

            <div className=" col-xl-3 col-lg-3 col-md-3 col-sm-12 d-none d-lg-block footer-widget widget-recent-posts">
              <h5 className="widget-title">{t("suuliin")}</h5>
              <article className="sigma_recent-post">
                <a state={{ id: 1 }} href="/medeeD?id=1">
                  <img
                    src="meeting.jpg"
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      height: "60px",
                    }}
                    alt="post"
                  />
                </a>
                <div className="sigma_recent-post-body">
                  <a state={{ id: 1 }} href="/medeeD?id=1">
                    {" "}
                    <i className="far fa-calendar" /> {t("news_day_meeting")}
                  </a>
                  <h6>
                    {" "}
                    <a state={{ id: 1 }} href="/medeeD?id=1">
                      {t("news_title_meeting")}
                    </a>{" "}
                  </h6>
                </div>
              </article>
              <article className="sigma_recent-post">
                <a state={{ id: 9 }} href="/medeeD?id=9">
                  <img
                    src="surgalt1.jpg"
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      height: "60px",
                    }}
                    alt="post"
                  />
                </a>
                <div className="sigma_recent-post-body">
                  <a state={{ id: 9 }} href="/medeeD?id=9">
                    {" "}
                    <i className="far fa-calendar" /> {t("news_day_surgalt")}
                  </a>
                  <h6>
                    {" "}
                    <a state={{ id: 9 }} href="/medeeD?id=9">
                      {t("news_title_surgalt")}
                    </a>{" "}
                  </h6>
                </div>
              </article>
              <article className="sigma_recent-post">
                <a state={{ id: 8 }} href="/medeeD?id=8">
                  <img
                    src="jado1.jpg"
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      height: "60px",
                    }}
                    alt="post"
                  />
                </a>
                <div className="sigma_recent-post-body">
                  <a state={{ id: 8 }} href="/medeeD?id=8">
                    {" "}
                    <i className="far fa-calendar" /> {t("news_day_jado")}
                  </a>
                  <h6>
                    {" "}
                    <a state={{ id: 8 }} href="/medeeD?id=8">
                      {t("news_title_jado")}
                    </a>{" "}
                  </h6>
                </div>
              </article>
              <article className="sigma_recent-post">
                <a state={{ id: 7 }} href="/medeeD?id=7">
                  <img
                    src="handmaa.jpg"
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      height: "60px",
                    }}
                    alt="post"
                  />
                </a>
                <div className="sigma_recent-post-body">
                  <a state={{ id: 7 }} href="/medeeD?id=7">
                    {" "}
                    <i className="far fa-calendar" /> {t("news_day_handmaa")}
                  </a>
                  <h6>
                    {" "}
                    <a state={{ id: 7 }} href="/medeeD?id=7">
                      {t("news_title_handmaa")}
                    </a>{" "}
                  </h6>
                </div>
              </article>
              <article className="sigma_recent-post">
                <a state={{ id: 6 }} href="/medeeD?id=6">
                  <img
                    src="hatan.jpg"
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      height: "60px",
                    }}
                    alt="post"
                  />
                </a>
                <div className="sigma_recent-post-body">
                  <a state={{ id: 6 }} href="/medeeD?id=6">
                    {" "}
                    <i className="far fa-calendar" /> {t("news_day_hatan")}
                  </a>
                  <h6>
                    {" "}
                    <a state={{ id: 6 }} href="/medeeD?id=6">
                      {t("news_title_hatan")}
                    </a>{" "}
                  </h6>
                </div>
              </article>
              {/* <article className="sigma_recent-post">
                <a state={{ id: 5 }} href="/medeeD?id=5">
                  <img src="medee4(1).jpg" alt="post" />
                </a>
                <div className="sigma_recent-post-body">
                  <a state={{ id: 5 }} href="/medeeD?id=5">
                    {" "}
                    <i className="far fa-calendar" /> {t("news_4_day")}
                  </a>
                  <h6>
                    {" "}
                    <a state={{ id: 5 }} href="/medeeD?id=5">
                      {t("news_title_4")}
                    </a>{" "}
                  </h6>
                </div>
              </article> */}
              {/* <article className="sigma_recent-post">
                <a state={{ id: 3 }} href="/medeeD?id=3">
                  <img src="news.jpg" alt="post" />
                </a>
                <div className="sigma_recent-post-body">
                  <a state={{ id: 3 }} href="/medeeD?id=3">
                    {" "}
                    <i className="far fa-calendar" /> {t("news_ognoo")}
                  </a>
                  <h6>
                    {" "}
                    <a state={{ id: 3 }} href="/medeeD?id=3">
                      {t("news_title")}
                    </a>{" "}
                  </h6>
                </div>
              </article> */}

              {/* <article className="sigma_recent-post">
                <a state={{id:2}} href="/medeeD?id=2">
                  <img src="baishin.jpg" alt="post" />
                </a>
                <div className="sigma_recent-post-body">
                  <a state={{id:2}} href="/medeeD?id=2">
                    {" "}
                    <i className="far fa-calendar" /> {t("sum_hiid_day")}
                  </a>
                  <h6>
                    {" "}
                    <a state={{id:2}} href="/medeeD?id=2">{t("sum_hiid")}</a>{" "}
                  </h6>
                </div>
              </article> */}
              {/* <article className="sigma_recent-post">
                <a state={{id:4}} href="/medeeD?id=4">
                  <img src="/odriin_bayr2.jpg" alt="post" />
                </a>
                <div className="sigma_recent-post-body">
                  <a state={{id:4}} href="/medeeD?id=4">
                    {" "}
                    <i className="far fa-calendar" /> {t("day")}
                  </a>
                  <h6>
                    {" "}
                    <a state={{id:4}} href="/medeeD?id=4">{t("buddha")}</a>{" "}
                  </h6>
                </div>
              </article> */}
            </div>
          </div>
        </div>
      </div>
      <div className="sigma_footer-bottom">
        <div className="container-fluid ">
          <div></div>
          <div className="sigma_footer-copyright ">
            <p className="footer ">
              {" "}
              {t("footer")}{" "}
              <a href="https://www.fununity.mn/" target="_blank">
                FUNUNITY LLC{" "}
              </a>
              <a href="#" className="custom-primary">
                2023
              </a>{" "}
            </p>
          </div>
          <ul className="sigma_sm square light">
            <li>
              <a href="https://www.facebook.com/Bumankhand" target="_blank">
                <i className="fab fa-facebook-f" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@kunze2264/videos"
                target="_blank"
              >
                <i className="fab fa-youtube" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
