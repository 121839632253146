import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import Footer from "./components/Footer";
import Header from "./components/Header";
import AboutPage from "./pages/about";
import GishuudPage from "./pages/gishuud/gishuud";
import ContactPage from "./pages/contact/contact";
import TosolPage from "./pages/tosol/tosol";
import MedeeDPage from "./pages/medeeD/medeeD";
import BichlegPage from "./pages/bichleg/bichleg";
import HandiwPage from "./pages/handiw/handiw";
import HandiwtuhaiPage from "./pages/handiw/handiwiin-tuhai";
import HolbooPage from "./pages/holboo/holboo";
import HolbootuhaiPage from "./pages/holboo/holbooniituhai";
import SurgaltPage from "./pages/surgalt/surgalt";
import GishuudtuhaiPage from "./pages/gishuudiin-tuhai/gushuudtuhai";
import ZuragPage from "./pages/zurag/zurag";
import useTranslate from "./hooks/useTranslate";
import TranslationProvider from "./hooks/TranslationProvider";
import BagshPage from "./pages/bagsh/bagsh";
import JavzandambaPage from "./pages/gishuudiin-tuhai/javzandamba";
import OrgolGolomt from "./pages/orgol_handiw/golomt_orgol";
import SaranGua from "./pages/gishuuds/sarangua";
import Gishuun3Page from "./pages/gishuuds/gishuun3";
import Gishuun4Page from "./pages/gishuuds/gishuun4";
import TerguunPage from "./pages/gishuuds/terguun";
import Gishuun5Page from "./pages/gishuuds/gishuun5";
import Layout from "./components/Layout";

function App() {
  const language = localStorage.getItem("language") ?? "mn";

  return (
    <React.Fragment>
      <TranslationProvider initialLanguage={language}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="gishuud" element={<GishuudPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="tosol" element={<TosolPage />} />
            <Route path="medeed" element={<MedeeDPage />} />
            <Route path="bichleg" element={<BichlegPage />} />
            <Route path="handiw" element={<HandiwPage />} />
            <Route path="handiwtuhai" element={<HandiwtuhaiPage />} />
            <Route path="holboo" element={<HolbooPage />} />
            <Route path="holbootuhai" element={<HolbootuhaiPage />} />
            <Route path="surgalt" element={<SurgaltPage />} />
            <Route path="gishuudtuhai" element={<GishuudtuhaiPage />} />
            <Route path="zurag" element={<ZuragPage />} />
            <Route path="bagsh" element={<BagshPage />} />
            <Route path="javzan" element={<JavzandambaPage />} />
            <Route path="sarangua" element={<SaranGua />} />
            <Route path="gishuun3" element={<Gishuun3Page />} />
            <Route path="gishuun4" element={<Gishuun4Page />} />
            <Route path="gishuun5" element={<Gishuun5Page />} />
            <Route path="terguun" element={<TerguunPage />} />
          </Route>
          <Route path="golomt" element={<OrgolGolomt />} />
        </Routes>
      </TranslationProvider>
    </React.Fragment>
  );
}

export default App;
