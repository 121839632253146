const HolbooPage = ()=>{
    return(
        <div>
            <div className="sigma_subheader dark-overlay dark-overlay-2"
            style={
                {
                    backgroundImage:"url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)"
                }
            }
            // style="background-image: "
            >

<div className="container">
  <div className="sigma_subheader-inner">
    <div className="sigma_subheader-text">
      <h1>Community</h1>
    </div>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><a className="btn-link" href="#">Home</a></li>
        <li className="breadcrumb-item active" aria-current="page">Community</li>
      </ol>
    </nav>
  </div>
</div>

</div>



<div className="section section-padding">
<div className="container">

  <div className="text-center filter-items">
    <h5 className="active portfolio-trigger" data-filter="*">All</h5>
    <h5 className="portfolio-trigger" data-filter=".coaching">Coaching</h5>
    <h5 className="portfolio-trigger" data-filter=".stakeholder">Stakeholder</h5>
    <h5 className="portfolio-trigger" data-filter=".strategy">Strategy</h5>
    <h5 className="portfolio-trigger" data-filter=".relations">Relations</h5>
  </div>

  <div className="portfolio-filter row">
    <div className="col-lg-4 coaching">
      <div className="sigma_portfolio-item">
        <img src="https://templatebae.net/themes/html/stupa/assets/img/community/13.jpg" alt="portfolio"/>
        <div className="sigma_portfolio-item-content">
          <div className="sigma_portfolio-item-content-inner">
            <h5> <a href="community-details.html"> Meditation </a> </h5>
            <p>An annual meditation meeting for spiritual trainers and practitioners</p>
          </div>
          <a href="community-details.html"><i className="fal fa-plus"></i></a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 strategy">
      <div className="sigma_portfolio-item">
        <img src="https://templatebae.net/themes/html/stupa/assets/img/community/14.jpg" alt="portfolio"/>
        <div className="sigma_portfolio-item-content">
          <div className="sigma_portfolio-item-content-inner">
            <h5> <a href="community-details.html"> Mindfulness </a> </h5>
            <p>An annual meditation meeting for spiritual trainers and practitioners</p>
          </div>
          <a href="community-details.html"><i className="fal fa-plus"></i></a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 coaching strategy">
      <div className="sigma_portfolio-item">
        <img src="https://templatebae.net/themes/html/stupa/assets/img/community/15.jpg" alt="portfolio"/>
        <div className="sigma_portfolio-item-content">
          <div className="sigma_portfolio-item-content-inner">
            <h5> <a href="community-details.html"> Buddhism </a> </h5>
            <p>An annual meditation meeting for spiritual trainers and practitioners</p>
          </div>
          <a href="community-details.html"><i className="fal fa-plus"></i></a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 relations coaching">
      <div className="sigma_portfolio-item">
        <img src="https://templatebae.net/themes/html/stupa/assets/img/community/16.jpg" alt="portfolio"/>
        <div className="sigma_portfolio-item-content">
          <div className="sigma_portfolio-item-content-inner">
            <h5> <a href="community-details.html"> Philosophy </a> </h5>
            <p>An annual meditation meeting for spiritual trainers and practitioners</p>
          </div>
          <a href="community-details.html"><i className="fal fa-plus"></i></a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 stakeholder strategy">
      <div className="sigma_portfolio-item">
        <img src="https://templatebae.net/themes/html/stupa/assets/img/community/17.jpg" alt="portfolio"/>
        <div className="sigma_portfolio-item-content">
          <div className="sigma_portfolio-item-content-inner">
            <h5> <a href="community-details.html"> Community </a> </h5>
            <p>An annual meditation meeting for spiritual trainers and practitioners</p>
          </div>
          <a href="community-details.html"><i className="fal fa-plus"></i></a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 stakeholder">
      <div className="sigma_portfolio-item">
        <img src="https://templatebae.net/themes/html/stupa/assets/img/community/12.jpg" alt="portfolio"/>
        <div className="sigma_portfolio-item-content">
          <div className="sigma_portfolio-item-content-inner">
            <h5> <a href="community-details.html"> Retreats </a> </h5>
            <p>An annual meditation meeting for spiritual trainers and practitioners</p>
          </div>
          <a href="community-details.html"><i className="fal fa-plus"></i></a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 coaching">
      <div className="sigma_portfolio-item">
        <img src="https://templatebae.net/themes/html/stupa/assets/img/community/7.jpg" alt="portfolio"/>
        <div className="sigma_portfolio-item-content">
          <div className="sigma_portfolio-item-content-inner">
            <h5> <a href="community-details.html"> Sunday Ministry </a> </h5>
            <p>An annual meditation meeting for spiritual trainers and practitioners</p>
          </div>
          <a href="community-details.html"><i className="fal fa-plus"></i></a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 relations">
      <div className="sigma_portfolio-item">
        <img src="https://templatebae.net/themes/html/stupa/assets/img/community/8.jpg" alt="portfolio"/>
        <div className="sigma_portfolio-item-content">
          <div className="sigma_portfolio-item-content-inner">
            <h5> <a href="community-details.html"> Buddhism's Ministry </a> </h5>
            <p>An annual meditation meeting for spiritual trainers and practitioners</p>
          </div>
          <a href="community-details.html"><i className="fal fa-plus"></i></a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 relations coaching">
      <div className="sigma_portfolio-item">
        <img src="https://templatebae.net/themes/html/stupa/assets/img/community/9.jpg" alt="portfolio"/>
        <div className="sigma_portfolio-item-content">
          <div className="sigma_portfolio-item-content-inner">
            <h5> <a href="community-details.html"> Labour Ministry </a> </h5>
            <p>An annual meditation meeting for spiritual trainers and practitioners</p>
          </div>
          <a href="community-details.html"><i className="fal fa-plus"></i></a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 stakeholder strategy">
      <div className="sigma_portfolio-item">
        <img src="https://templatebae.net/themes/html/stupa/assets/img/community/10.jpg" alt="portfolio"/>
        <div className="sigma_portfolio-item-content">
          <div className="sigma_portfolio-item-content-inner">
            <h5> <a href="community-details.html"> Event Ministry </a> </h5>
            <p>An annual meditation meeting for spiritual trainers and practitioners</p>
          </div>
          <a href="community-details.html"><i className="fal fa-plus"></i></a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 stakeholder">
      <div className="sigma_portfolio-item">
        <img src="https://templatebae.net/themes/html/stupa/assets/img/community/11.jpg" alt="portfolio"/>
        <div className="sigma_portfolio-item-content">
          <div className="sigma_portfolio-item-content-inner">
            <h5> <a href="community-details.html"> Temple Minsitry </a> </h5>
            <p>An annual meditation meeting for spiritual trainers and practitioners</p>
          </div>
          <a href="community-details.html"><i className="fal fa-plus"></i></a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 coaching">
      <div className="sigma_portfolio-item">
        <img src="https://templatebae.net/themes/html/stupa/assets/img/community/12.jpg" alt="portfolio"/>
        <div className="sigma_portfolio-item-content">
          <div className="sigma_portfolio-item-content-inner">
            <h5> <a href="community-details.html"> Men Ministry </a> </h5>
            <p>An annual meditation meeting for spiritual trainers and practitioners</p>
          </div>
          <a href="community-details.html"><i className="fal fa-plus"></i></a>
        </div>
      </div>
    </div>
  </div>

</div>
</div>



<div className="section dark-overlay dark-overlay-3 bg-cover bg-center bg-norepeat" style="background-image: url(assets/img/bg1.jpg)">
<div className="container">
  <div className="row align-items-center">
    <div className="col-lg-6 mb-lg-30">
      <form method="post">
        <div className="form-row">
          <div className="col-lg-6">
            <div className="form-group">
              <i className="far fa-user"></i>
              <input type="text" className="form-control transparent" placeholder="First Name" name="fname" value=""/>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <i className="far fa-user"></i>
              <input type="text" className="form-control transparent" placeholder="Last Name" name="lname" value=""/>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <i className="far fa-pencil"></i>
              <input type="text" className="form-control transparent" placeholder="Subject" name="subject" value=""/>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <i className="far fa-envelope"></i>
              <input type="text" className="form-control transparent" placeholder="Email Address" name="email" value=""/>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <textarea name="message" className="form-control transparent" placeholder="Enter Message" rows="4"></textarea>
            </div>
          </div>
          <div className="col-lg-12">
            <button type="submit" className="sigma_btn-custom d-block w-100" name="button"> Get a Quote <i className="far fa-arrow-right"></i> </button>
          </div>
        </div>
      </form>
    </div>
    <div className="col-lg-6">
      <div className="row">
        <div className="col-lg-6">
          <div className="sigma_client">
            <img src="https://templatebae.net/themes/html/stupa/assets/img/clients/1.png" alt="client"/>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="sigma_client">
            <img src="https://templatebae.net/themes/html/stupa/assets/img/clients/2.png" alt="client"/>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="sigma_client">
            <img src="https://templatebae.net/themes/html/stupa/assets/img/clients/3.png" alt="client"/>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="sigma_client">
            <img src="https://templatebae.net/themes/html/stupa/assets/img/clients/4.png" alt="client"/>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="sigma_client">
            <img src="https://templatebae.net/themes/html/stupa/assets/img/clients/5.png" alt="client"/>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="sigma_client">
            <img src="https://templatebae.net/themes/html/stupa/assets/img/clients/6.png" alt="client"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>



<div className="section section-padding">
<div className="container">

  <div className="section-title text-center">
    <p className="subtitle">Blog</p>
    <h4 className="title">News Feed</h4>
  </div>

  <div className="row">


    <div className="col-lg-4 col-md-6">
      <article className="sigma_post">
        <div className="sigma_post-thumb">
          <a href="blog-details.html">
            <img src="assets/img/blog/2.jpg" alt="post"/>
          </a>
        </div>
        <div className="sigma_post-body">
          <div className="sigma_post-meta">
            <div className="me-3">
              <i className="far fa-dharmachakra"></i>
              <a href="blog-details.html" className="sigma_post-category">Temple</a>,
              <a href="blog-details.html" className="sigma_post-category">Love</a>
            </div>
            <a href="blog-details.html" className="sigma_post-date"> <i className="far fa-calendar"></i> May 20, 2022</a>
          </div>
          <h5> <a href="blog-details.html">Indian Mantras for Meditation</a> </h5>
          <div className="sigma_post-single-author">
            <img src="assets/img/people/1.jpg" alt="author"/>
            <div className="sigma_post-single-author-content">
              By <p>Dharma</p>
            </div>
          </div>
        </div>
      </article>
    </div>



    <div className="col-lg-4 col-md-6">
      <article className="sigma_post">
        <div className="sigma_post-thumb">
          <a href="blog-details.html">
            <img src="assets/img/blog/1.jpg" alt="post"/>
          </a>
        </div>
        <div className="sigma_post-body">
          <div className="sigma_post-meta">
            <div className="me-3">
              <i className="far fa-dharmachakra"></i>
              <a href="blog-details.html" className="sigma_post-category">Temple</a>,
              <a href="blog-details.html" className="sigma_post-category">Love</a>
            </div>
            <a href="blog-details.html" className="sigma_post-date"> <i className="far fa-calendar"></i> May 20, 2022</a>
          </div>
          <h5> <a href="blog-details.html">Indian Mantras for Meditation</a> </h5>
          <div className="sigma_post-single-author">
            <img src="assets/img/people/2.jpg" alt="author"/>
            <div className="sigma_post-single-author-content">
              By <p>Dharma</p>
            </div>
          </div>
        </div>
      </article>
    </div>



    <div className="col-lg-4 col-md-6">
      <article className="sigma_post">
        <div className="sigma_post-thumb">
          <a href="blog-details.html">
            <img src="assets/img/blog/3.jpg" alt="post"/>
          </a>
        </div>
        <div className="sigma_post-body">
          <div className="sigma_post-meta">
            <div className="me-3">
              <i className="far fa-dharmachakra"></i>
              <a href="blog-details.html" className="sigma_post-category">Temple</a>,
              <a href="blog-details.html" className="sigma_post-category">Love</a>
            </div>
            <a href="blog-details.html" className="sigma_post-date"> <i className="far fa-calendar"></i> May 20, 2022</a>
          </div>
          <h5> <a href="blog-details.html">Indian Mantras for Meditation</a> </h5>
          <div className="sigma_post-single-author">
            <img src="assets/img/people/1.jpg" alt="author"/>
            <div className="sigma_post-single-author-content">
              By <p>Dharma</p>
            </div>
          </div>
        </div>
      </article>
    </div>


  </div>

</div>
</div>


        </div>
    )
}
export default HolbooPage;