import useTranslate from "../../hooks/useTranslate";
const ContactPage = () => {
  const { language, setLanguage, t } = useTranslate("mn");
    return(
        <div>
            <div className="sigma_subheader dark-overlay dark-overlay-2" 
  style={
    {
        backgroundImage: " url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
        height: "60vh",
        paddingTop: "25vh"
    }
  }
  //style="background-image: url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)"
  >

    <div className="container">
      <div className="sigma_subheader-inner">
        <div className="sigma_subheader-text">
          <h1>{t("holboo")}</h1>
        </div>
        
      </div>
    </div>

  </div>
  <div className="sigma_map">
    <iframe src="https://maps.google.com/maps?q=ula&t=&z=5&ie=UTF8&iwloc=&output=embed" allowfullscreen=""></iframe>
  </div>
  
  <div className="section mt-negative pt-0">
    <div className="container">

      <form className="sigma_box box-lg m-0 mf_form_validate ajax_submit" action="sendmail.php" method="post" enctype="multipart/form-data" novalidate="novalidate">
        <div className="row">
          
          <div className="holboo col-lg-4">
            <div className="form-group">
              <i className="far fa-user"></i>
              <input type="text" placeholder={t("owog")} className="form-control dark" name="name" />
            </div>
          </div>
          <div className="holboo col-lg-4">
            <div className="form-group">
              <i className="far fa-envelope"></i>
              <input type="email" placeholder={t("e_mail")} className="form-control dark" name="email" />
            </div>
          </div>
          <div className="holboo col-lg-4">
            <div className="form-group">
              <i className="far fa-pencil"></i>
              <input type="text" placeholder={t("aguulga")} className="form-control dark" name="Subesubject" />
            </div>
          </div>
          
        </div>
        <div className="form-group">
          <textarea name="message" placeholder="Тeкст" cols="45" rows="5" className="form-control dark"></textarea>
        </div>
        <div className="text-center">
          <button type="submit" className="sigma_btn-custom" name="button">{t("ilgeeh")}</button>
          <div className="server_response w-100">
          </div>
        </div>
      </form>

    </div>
  </div>
  <div className="section section-padding pt-0">
    <div className="container">
      <div className="row">

        <div className="col-lg-4">
          <div className="sigma_icon-block text-center light icon-block-7">
            <i className="flaticon-email"></i>
            <div className="sigma_icon-block-content">
              <span>{t("E_ilgeeh")} <i className="far fa-arrow-right"></i> </span>
              
              <p>INFO@BUDDHISTWOMEN.MN</p>
            </div>
            <div className="icon-wrapper">
              <i className="flaticon-email"></i>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="sigma_icon-block text-center light icon-block-7">
            <i className="flaticon-phone"></i>
            <div className="sigma_icon-block-content">
              <span>{t("holboo")}<i className="far fa-arrow-right"></i> </span>
              
              <p> (+976) 99087125 </p>
              <p> (+976) 70183588 </p>
            </div>
            <div className="icon-wrapper">
              <i className="flaticon-phone"></i>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="sigma_icon-block text-center light icon-block-7">
            <i className="flaticon-location"></i>
            <div className="sigma_icon-block-content">
              <span>{t("H_bairshil")} <i className="far fa-arrow-right"></i> </span>
             
              <p>{t("H_hayg")}</p>
              <p>{t("H_hayg1")}</p>
            </div>
            <div className="icon-wrapper">
              <i className="flaticon-location"></i>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  
        </div>
    );
}
export default ContactPage;