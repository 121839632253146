
export default function DescriptionIconSection() {


    return (
        <div className="section mb-negative p-0">
            <div className="container">
                
            </div>
        </div>

    )
}