import useTranslate from "../../hooks/useTranslate";
import React from "react";

const JavzandambaPage = () => {
  const { language, setLanguage, t } = useTranslate("mn");
  return (
    <div>
      <div
        class="sigma_subheader dark-overlay dark-overlay-2"
        style={{
          backgroundImage:
            "url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
          height: "60vh",
          paddingTop: "25vh",
        }}
        // style="background-image: "
      >
        <div class="container">
          <div class="sigma_subheader-inner">
            <div class="sigma_subheader-text">
              <h1>{t("bagsh_tuhai_title")}</h1>
            </div>
            
          </div>
        </div>
      </div>

      <div class="section sigma_post-single">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="entry-content">
                <div class="sigma_volunteer-detail mb-5">
                  <div class="row">
                    <div class="col-lg-5">
                      <div class="sigma_member-image style-1">
                        <img src="javzan_damb.jpg_large" class="mb-0" alt="volunteer" style={{height:"400px",}} />
                      </div>
                    </div>
                    <div class="col-lg-7">
                      <div class="sigma_volunteer-detail-inner mt-5 mt-lg-0 ps-0 ps-lg-4">
                        <h3 class="sigma_member-name">{t("javz_Fname")}</h3>
                        <span class="sigma_volunteer-detail-category">
                        {t("bagsh_tuhai_title")}
                        </span>
                        <ul class="sigma_volunteer-detail-info">
                          <li>
                            <i class="fas fa-phone"></i>
                            <span class="title">{t("utas")}:</span>(+976) 9999-9999,
                            +976 9999-9999
                          </li>
                          <li>
                            <i class="fas fa-envelope"></i>
                            <span class="title">{t("e_mail")}:</span>info@example.com,
                            example@example.com
                          </li>
                          <li>
                            <i class="fas fa-map-marker-alt"></i>
                            <span class="title">{t("hayg")}:</span>
                          </li>
                        </ul>
                        <ul class="sigma_volunteer-detail-info">
                          <li>
                            <i class="fab fa-facebook-f"></i>
                            <span class="title">Facebook:</span>
                            <a href="#"> </a>
                          </li>

                          
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <p>
                  <GishuudItem
                  title={t("javz_title")}
                  id="yriltsalga"
                  >
                    <p> {t("jawz_text")} </p>
                    <p> {t("jawz_text1")} </p>
                    <p> {t("jawz_text2")} </p>
                    <p> {t("jawz_text3")} </p>
                    <p> {t("jawz_text4")} </p>
                    <p> {t("jawz_text5")} </p>
                    <p> {t("jawz_text6")} </p>
                    <p> {t("jawz_text7")} </p>
                    <p> {t("jawz_text8")} </p>
                    <p> {t("jawz_text9")} </p>
                    <p> {t("jawz_text10")} </p>
                    <p> {t("jawz_text11")} </p>
                    <p> {t("jawz_text12")} </p>
                    <p> {t("jawz_text13")} </p>
                    <p> {t("jawz_text14")} </p>
                    <p> {t("jawz_text15")} </p>
                   
                  </GishuudItem>
                  <GishuudItem
                  title={t("ailchlal")}
                  id="ailchlal"
                  >
                    <p> {t("ailchlal_text")} </p>
                   
                  </GishuudItem>
                                                      
                  <GishuudItem
                    title={t("mongold")}
                    id="mongol_uls"
                  >
                    <p> {t("mongold_text")} </p>
                    <p> {t("mongold_text1")} </p>
                    <p> {t("mongold_text2")} </p>
                    <p> {t("mongold_text3")} </p>
                    <p> {t("mongold_text4")} </p>
                    <p> {t("mongold_text5")} </p>
                    <p> {t("mongold_text6")} </p>
                    <p> {t("mongold_text7")} </p>
                    <p> {t("mongold_text8")} </p>
                    <p> {t("mongold_text9")} </p>
                  </GishuudItem>
                </p>
                <hr />
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JavzandambaPage;

function GishuudItem({ title, children, id }) {
  const [isShow, setIsShow] = React.useState(false);
  return (
    <div className="accordion with-gap" id={`${id}_parent`}>
      <div className="card">
        <div
          className="card-header"
          //data-bs-toggle="collapse"
          //role="button"
          //data-bs-target="#generalOne"
          //aria-expanded="true"
          //aria-controls="generalOne"
          onClick={() => setIsShow(!isShow)}
        >
          {title}
        </div>
        <div
          id={id + ""}
          className={`collapse ${isShow ? "show" : ""}`}
          data-bs-parent={`${id}_parent`}
        >
          <div className="card-body">
            <p
              style={
                {
                  // color: "gray",
                }
              }
            >
              {children}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
