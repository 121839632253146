import useTranslate from "../hooks/useTranslate";
export default function StudySection() {
  const { language, setLanguage, t } = useTranslate("mn");
  return (
    <div className="section section-padding">
      <div className="container">
        <div className="section-title section-title-2 text-center">
          <h4 className="title">{t("surgalt_title")}</h4>
        </div>
        <div className="row" style={{display: "flex", flexWrap: "wrap"}}>
          <div className="col-lg-4 col-md-6">
            <a href="/surgalt" className="sigma_service style-2">
              <div className="sigma_service-thumb">
                <img src="/tsagaan_dari_eh.jpg" alt="img" style={{height:"200px"}}/>
                <i className="flaticon-leaf" />
              </div>
              <div className="sigma_service-body">
                <h5>{t("dari_eh")}</h5>
                <p>
                  {t("dari_eh_text")}
                </p>
                <p>
                  {t("dari_eh_text1")}
                </p>
                <p>
                  {t("dari_eh_text2")}
                </p>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a href="/surgalt" className="sigma_service style-2">
              <div className="sigma_service-thumb">
                <img src="assets/img/buddha_images/IMG-6965.JPG" alt="img" style={{height:"200px"}} />
                <i className="flaticon-buddhas-footprint" />
              </div>
              <div className="sigma_service-body">
                <h5>{t("jod")} </h5>
                <p>
                  {t("jod_text")}
                </p>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a href="/surgalt" className="sigma_service style-2">
              <div className="sigma_service-thumb">
                <img src="/altangerel_sudar.jpg" alt="img" style={{height:"200px"}}/>
                <i className="flaticon-hand-1" />
              </div>
              <div className="sigma_service-body">
                <h5>{t("altan")}</h5>
                <p>
                  {t("altan_text")}
                </p>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a href="/surgalt" className="sigma_service style-2">
              <div className="sigma_service-thumb">
                <img
                  src="/nogoon_dari_eh.jpg"
                  alt="img"
                  style={{height:"200px"}}
                />
                <i className="flaticon-buddha" />
              </div>
              <div className="sigma_service-body">
                <h5>{t("dari_eh_del")} </h5>
                <p>
                  {t("dari_eh_del_text")}
                </p>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a href="/surgalt" className="sigma_service style-2">
              <div className="sigma_service-thumb">
                <img
                  src="/towd.jpeg"
                  alt="img"
                  style={{height:"200px"}}
                />
                <i className="flaticon-rattle" />
              </div>
              <div className="sigma_service-body">
                <h5>{t("towd")}</h5>
                <p>
                  {t("towd_text")}
                </p>
              </div>
            </a>
          </div>
          {/* <div className="col-lg-4 col-md-6">
                        <a href="buddhism-details.html" className="sigma_service style-2">
                            <div className="sigma_service-thumb">
                                <img src="https://templatebae.net/themes/html/stupa/assets/img/service/10.jpg" alt="img" />
                                <i className="flaticon-yin-yang" />
                            </div>
                            <div className="sigma_service-body">
                                <h5>Retreats</h5>
                                <p>Buddhism is a way to wisdom, peace, harmony of mind and soul, an exploration of the life in all its forms. </p>
                            </div>
                        </a>
                    </div> */}
        </div>
      </div>
    </div>
  );
}
