import AboutSection from "../../components/AboutSection";
import React from "react";
import  { PorfolioNoslider } from "../../components/PorfolioSection";
import FAQSection from "../../components/FAQSection";
import AboutVideo from "../../components/AboutVideo";
import BlogSection from "../../components/BlogSection";
import DescriptionIconSection from "../../components/DescriptionIconSection";
import StudySection from "../../components/StudySection";
import Banner from "../../components/Banner";
export default function HomePage(){
    return (
        <React.Fragment>
            <Banner/>
            <AboutSection/>
            {/* <PorfolioSection/> */}
            <FAQSection/>
            <AboutVideo/>
            <StudySection/>
            <PorfolioNoslider/>
            <BlogSection/>
            <DescriptionIconSection/>
        </React.Fragment>
    )
}