
import useTranslate from "../hooks/useTranslate";
import React ,{useRef} from "react";

export default function AboutSection() {
  const [SelectVideo, setSelectVideo] = React.useState(false);
  const { language, setLanguage, t } = useTranslate("mn");
  const onVideo = (event) => {
    setSelectVideo(!SelectVideo);
  };
 

  return (
    <section className="section  light-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-lg-30">
            <div className="section-title section-title-2 text-start">
              <p className="subtitle">{t("bidnii")}</p>
              <h4 className="title">{t("taniltsuuulga")}</h4>
              <p>{t("about_text")}</p>
              {/* <p>
                                Тус холбооны Буддын шашинтай хүмүүст зориулсан сургалт, хөтөлбөрүүдэд
                                хамрагдагч гадаадад амьдарч буй иргэд болон орон нутгаас идэвхтэй оролцогч
                                2000 гаруй гишүүд, нийслэл хотоос үйл ажиллагааг дэмжигч 100 орчим нь идэвхтэн
                                гишүүдтэйгээр олон нийтэд зөв мэдлэг түгээх үйлсэд хувь нэмрээ оруулсаар
                                байна.
                            </p> */}
            </div>

            {/* <div className="d-flex align-items-center mt-5">

                            <div>
                                <h5 className="mb-2">Эрхэм зорилго</h5>
                                <p className="mb-0">Монголын буддист эмэгтэйчүүдийн харилцан холбоо, эв нэгдлийг
                                    бэхжүүлэх, байр сууриа бататгах, мэдлэг туршлагаа хуваалцах, энэрэн нигүүлсэхүй
                                    бурханы сургаалыг түгээн дэлгэрүүлэх үйлсэд манлайлагч байна.</p>
                            </div>

                        </div>
                        <div className="d-flex align-items-center mt-5">
                            <div className="sigma_round-button me-4 sm">
                                <span> <b className="counter" data-to={50} data-from={0}>0</b> <span className="custom-secondary">%</span> </span>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 197 197" enableBackground="new 0 0 197 197" xmlSpace="preserve">
                                    <circle className="sigma_round-button-stroke" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                                    <circle data-to={400} className="sigma_progress-round sigma_round-button-circle secondary" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                                </svg>
                            </div>
                            <div>
                                <h5 className="mb-2">Active Members</h5>
                                <p className="mb-0">Buddhism is a way to wisdom, peace, harmony of mind and soul, an exploration of the life in all its forms.</p>
                            </div>
                        </div> */}
          </div>
          <div className="col-lg-6 d-lg-block">
            <div className="me-lg-30 img-group-2">
              {/* <video  controls style={{width:"100%"}}>
                                <source src="https://archive.org/details/buman-khand-taniltsuulga.mp-4" href="" type="video/mp4">
                                </source>                            
                            </video> */}
              <video onClick={(event) => onVideo(event)} width="100%" controls>
                <source
                  src="https://ia802606.us.archive.org/26/items/buman-khand-taniltsuulga.mp-4/BUMAN%20KHAND%20TANILTSUULGA%20%281%29.mp4"
                  type="video/mp4"
                />
                <source src="movie.ogg" type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div
          className="sigma_box pb-0 mt-8 "
          style={{ background: "transparent", boxShadow: "none" }}
        >
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div
                className="sigma_icon-block icon-block-2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <div
                  className="icon-wrapper"
                  style={{ fontSize: "120px !important" }}
                >
                  <i className="flaticon-lotus" />
                </div>
                <div className="sigma_icon-block-content">
                  <h5 style={{ marginTop: "18px" }}> {t("mission")} </h5>
                  <p>{t("mission_text")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div
                className="sigma_icon-block icon-block-2 "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <div
                  className="icon-wrapper"
                  style={{ fontSize: "120px !important" }}
                >
                  <i className="flaticon-yin-yang" />
                </div>
                <div className="sigma_icon-block-content">
                  <h5 style={{ marginTop: "18px" }}> {t("zorilt")} </h5>
                  <p>{t("zorilt_text")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="video_clickon position-fixed"
          style={{ visibility: SelectVideo ? "visible" : "hidden" }}
          
        >
          <video  width="70%" id="video" controls>
            <source
              src="https://ia802606.us.archive.org/26/items/buman-khand-taniltsuulga.mp-4/BUMAN%20KHAND%20TANILTSUULGA%20%281%29.mp4"
              type="video/mp4"
            />
            <source src="movie.ogg" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
          <button className="video_x" onClick={onVideo} >x</button>
        </div>
      </div>
    </section>
  );
}
